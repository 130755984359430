import React from "react";
import { pluralize } from "numeralize-ru";
import "./Paywall.scss";

function Paywall({ prayersCount, onDecline }: any) {
  const price = 47;
  return (
    <div className="paywall">
      <div className="pw-head">Зажгите свечу</div>
      <div className="pw-text">
        Поддержите разработку православного приложения. Мы просим всего {price}{" "}
        {pluralize(price, "рубль", "рубля", "рублей")}, чтобы сделать молитву
        доступной для всех.
        {/* Попросите людей по всему миру помолиться вместе с вами и поддержите
        разработку православного приложения. */}
      </div>
      {/* <div className="pw-text">
        <em>
          «Каждый уделяй по расположению сердца, не с огорчением и не с
          принуждением; ибо доброхотно дающего любит Бог» (2 Кор 9:5-12).
        </em>
      </div> */}
      <div className="pw-text">
        Уже помогли больше 107 человек.
        {/* и вашу просьбу{" "}
        {pluralize(prayersCount, "увидит", "увидят", "увидят")} {prayersCount}{" "}
        {pluralize(prayersCount, "человек", "человека", "человек")}. */}
      </div>
      <form method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
        <input type="hidden" name="receiver" value="410011066206899" />
        <input
          type="hidden"
          name="formcomment"
          value="Пожертвование на развитие православного приложения для молитвы."
        />
        <input
          type="hidden"
          name="short-dest"
          value="Пожертвование на развитие православного приложения для молитвы."
        />
        <input
          type="hidden"
          name="successURL"
          value={`${process.env.REACT_APP_URL}/?success=2`}
        />
        <input type="hidden" name="label" value="$order_id" />
        <input type="hidden" name="quickpay-form" value="donate" />
        <input type="hidden" name="targets" value="Пожертвование" />
        <input type="hidden" name="sum" value={price} data-type="number" />
        <input type="hidden" name="need-fio" value="false" />
        <input type="hidden" name="need-email" value="false" />
        <input type="hidden" name="need-phone" value="false" />
        <input type="hidden" name="need-address" value="false" />
        <input type="hidden" name="paymentType" value="AC" />
        <input type="submit" className="pw-cta" value="Помочь" />
      </form>
      <button
        className="pw-decline"
        onClick={(e) => {
          e.preventDefault();
          onDecline();
        }}
      >
        Отказаться от помощи
      </button>
    </div>
  );
}

export default Paywall;
