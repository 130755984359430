import React from "react";
import "./Candle.scss";

function Candle() {
  return (
    <div className="candle">
      <div className="flame">
        <div className="shadows"></div>
        <div className="top"></div>
        <div className="middle"></div>
        <div className="bottom"></div>
      </div>
      <div className="wick"></div>
      <div className="wax"></div>
    </div>
  );
}

export default Candle;
