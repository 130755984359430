import React from "react";
import CandleStar from "./CandleStar";
import "./Altar.scss";

function Altar({ candles, onClick }: any) {
  return (
    <section className="candles" onClick={() => onClick()}>
      <div className="candlesRow">
        {candles.map((fire: boolean, i: number) => (
          <CandleStar key={i} isFire={fire} />
        ))}
      </div>
      {/* <div className="candlesMessage">
          {candlesRun[candlesRunIndex - 1]?.message} |
          <a href="#">Попросить помолиться</a>
        </div> */}
    </section>
  );
}

export default Altar;
