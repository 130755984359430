import React, { useEffect, useState } from 'react'
import classnames from 'classnames';
import './Line.scss'

interface ILineProps {
    text: string,
    symbolDuration: number,
    transition: number,
    pause: number,
}

function Line({ text, symbolDuration, transition, pause }: ILineProps) {
    const words = text.split(' ').map(
        (word: string, index: number, arr: Array<string>) => ({
            word,
            delay: transition + symbolDuration * arr.reduce((sum: number, w: string, i: number) => i < index ? sum += w.length + 1 : sum, 0),
            duration: symbolDuration * word.length
        })
    );
  
    return (
        <p className='lead'>
            { words.map(({word, delay, duration}: any, index: number) =>
                <span
                    key={index}
                    className='word animate'
                    style={{
                        animationDelay: `${delay}s`,
                        animationDuration: `${duration}s`,
                    }}
                >
                    {word}
                </span>
            )}
        </p>
    )
}

export default Line
