import React from "react";
import "./CandleStar.scss";

function CandleStar({ onClick, isFire }: any) {
  return (
    <img
      src={isFire ? "/img/candle-2.jpg" : "/img/candle-3.jpg"}
      className={`candleCol ${isFire ? "candleShowAnimation" : ""}`}
      onClick={onClick}
      alt=""
    />
  );
}

export default CandleStar;
