import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Line from "./Line";
import "./App.scss";
import { pluralize } from "numeralize-ru";
import Candle from "./Candle";
import Altar from "./Altar";
import prays from "./prays";
import Paywall from "./Paywall";

const secundsPerSymbol = 0.07;
const transitionTime = 0.3;
const pauseTime = 0.5;

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get("success");

  useEffect(() => {
    if (success) {
      window.onVkRetLoad = () => {
        window.VK.Retargeting.Event("donate-0");
      };
    }
  }, [success]);

  const [prayIndex, setPrayIndex] = useState(0);
  const [lineIndex, setLineIndex] = useState(0);
  const [prayersCount, setPrayersCount] = useState(
    90 + Math.ceil(Math.random() * 30)
  );
  const [candles, setCandles] = useState(Array<boolean>(30).fill(false));
  const [isPaywall, setIsPaywall] = useState(false);
  const [tick, setTick] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setTick((t) => t + 1);
      setPrayersCount((count) => count + Math.floor(Math.random() * 3) - 1);
      toggleCandle(Math.floor(Math.random() * 30));
    }, Math.floor(5000 + Math.random() * 5000));
  }, [tick]);

  useEffect(() => {
    setTimeout(() => {
      setLineIndex((index) => {
        if (index === prays[prayIndex].lines.length - 1) {
          setPrayIndex((index) => (index + 1) % prays.length);
        }
        return (index + 1) % prays[prayIndex].lines.length;
      });
    }, (transitionTime + pauseTime + prays[prayIndex].lines[lineIndex].length * secundsPerSymbol) * 1000);
  }, [prayIndex, lineIndex]);

  useEffect(() => {
    const CHANNEL_ID = "nBQJbK5Mo91mbcog";
    const drone = new window.Scaledrone(CHANNEL_ID, {});

    var room = drone.subscribe("observable-room");
    room.on("open", (error: any) => {
      if (error) {
        return console.error(error);
      }
      // Connected to room
    });

    room.on("members", (members: any) => {
      setPrayersCount((count) => count + members.length);
    });

    room.on("member_join", (member: any) => {
      setPrayersCount((count) => count + 1);
    });

    room.on("member_leave", (member: any) => {
      setPrayersCount((count) => count - 1);
    });
  }, []);

  const toggleCandle = (i: number) => {
    setCandles((cs) => {
      const ncs = [...cs];
      ncs[i] = !ncs[i];
      return ncs;
    });
  };

  const paywall = () => {
    window.VK.Retargeting.Event("click-altar-0");
    setIsPaywall(true);
  };

  return (
    <div className="container">
      {isPaywall ? (
        <Paywall
          prayersCount={prayersCount}
          onDecline={() => setIsPaywall(false)}
        />
      ) : (
        <>
          <header className="header">
            {success ? (
              <div
                style={{
                  marginTop: "40px",
                  marginBottom: "40px",
                  boxSizing: "border-box",
                }}
              >
                <Candle />
              </div>
            ) : (
              <Altar candles={candles} onClick={paywall} />
            )}
          </header>
          <main className="main">
            <Line
              key={lineIndex}
              text={prays[prayIndex].lines[lineIndex]}
              symbolDuration={secundsPerSymbol}
              transition={transitionTime}
              pause={pauseTime}
            />
            <p className="next" key={lineIndex + 1}>
              {prays[prayIndex].lines[lineIndex + 1]}
            </p>
          </main>
          {/* <footer className="footer" key={prayersCount}>
            {prayersCount < 1
              ? "Загрузка..."
              : `Вместе с вами молится ${prayersCount} ${pluralize(
                  prayersCount,
                  "человек",
                  "человека",
                  "человек"
                )}`}
          </footer> */}
        </>
      )}
    </div>
  );
}

export default App;
