interface IPray {
  name: string;
  // text: string,
  lines: Array<string>;
}

// const prays : Array<IPray> = [
//   {
//     name: 'Молитва об исцелении больного',
//     text: 'О, Премилосе́рдый Бо́же, О́тче, Сы́не и Святы́й Ду́ше, в Неразде́льней Тро́ице покланя́емый и сла́вимый, при́зри благоутро́бно на раба́ Твоего́ (и́мя), боле́знию одержи́маго; отпусти́ ему́ вся́ согреше́ния его́; пода́й ему́ исцеле́ние от боле́зни; возврати́ ему́ здра́вие и си́лы теле́сные; пода́й ему́ долгоде́нственное и благоде́нственное житие́, ми́рныя Твои́ и преми́рныя бла́га, что́бы о́н вме́сте с на́ми приноси́л благода́рныя мольбы́ Тебе́, Всеще́дрому Бо́гу и Созда́телю на́шему. Пресвята́я Богоро́дица, всеси́льным заступле́нием Твои́м помоги́ мне умоли́ть Сы́на Твоего́, Бо́га моего́, об исцеле́нии раба́ Бо́жия (и́мя). Все святы́е и а́нгелы Госпо́дни, моли́те Бо́га о больно́м рабе́ Его́ (и́мя). Ами́нь.',
//   }
// ];

const prays: Array<IPray> = [
  {
    name: "Отче наш",
    lines: [
      "Отче наш,",
      "Иже еси на небесе́х!",
      "Да святится имя Твое,",
      "да прии́дет Царствие Твое,",
      "да будет воля Твоя,",
      "яко на небеси́ и на земли́.",
      "Хлеб наш насущный",
      "да́ждь нам дне́сь;",
      "и оста́ви нам до́лги наша,",
      "якоже и мы оставляем",
      "должнико́м нашим;",
      "и не введи нас во искушение,",
      "но изба́ви нас от лукаваго.",
      "Яко Твое есть Царство",
      "и сила, и слава,",
      "Отца, и Сына,",
      "и Святаго Духа,",
      "ныне и присно,",
      "и во веки веков.",
      "Аминь.",
    ],
  },
  {
    name: "Богородице Дево, радуйся",
    lines: [
      "Богоро́дице Де́во, ра́дуйся,",
      "благода́тная Мари́е,",
      "Госпо́дь с Тобо́ю,",
      "Благослове́нна Ты в жена́х",
      "и благослове́н",
      "Плод чре́ва Твоего́,",
      "я́ко Спа́са родила́",
      "еси́ душ на́ших.",
    ],
  },
  {
    name: "Молитва последних оптинских старцев",
    lines: [
      "Господи, дай мне",
      "с душевным спокойствием",
      "встретить всё,",
      "что принесёт мне",
      "наступающий день.",
      "Дай мне всецело",
      "предаться воле",
      "Твоей Святой.",
      "На всякий час сего дня",
      "во всём наставь",
      "и поддержи меня.",
      "Какие бы я",
      "ни получал известия",
      "в течение дня,",
      "научи меня принять их",
      "со спокойной душою",
      "и твёрдым убеждением,",
      "что на всё",
      "Святая воля Твоя.",
      "Во всех словах",
      "и делах моих",
      "руководи моими",
      "мыслями и чувствами.",
      "Во всех",
      "непредвиденных случаях",
      "не дай мне забыть,",
      "что всё ниспослано Тобой.",
      "Научи меня прямо",
      "и разумно действовать",
      "с каждым членом",
      "семьи моей,",
      "никого не смущая",
      "и не огорчая.",
      "Господи, дай мне силу",
      "перенести утомление",
      "наступающего дня",
      "и все события",
      "в течение дня.",
      "Руководи моею волею",
      "и научи меня молиться,",
      "верить, надеяться,",
      "терпеть, прощать",
      "и любить.",
      "Аминь.",
    ],
  },
  {
    name: "Славословие",
    lines: [
      "Сла́ва Отцу́",
      "и Сы́ну",
      "и Свято́му Ду́ху,",
      "и ны́не и при́сно",
      "и во ве́ки веко́в.",
      "Ами́нь.",
    ],
  },
  {
    name: "Царю Небесный",
    lines: [
      "Царю́ Небе́сный,",
      "Уте́шителю,",
      "Ду́ше и́стины,",
      "И́же везде́ сый",
      "и вся исполня́яй,",
      "Сокро́вище благи́х",
      "и жи́зни Пода́телю,",
      "прииди́ и всели́ся в ны,",
      "и очи́сти ны",
      "от вся́кия скве́рны,",
      "и спаси́, Бла́же,",
      "ду́ши на́ша.",
    ],
  },
  {
    name: "Иисусова молитва",
    lines: [
      "Го́споди Иису́се Христе́,",
      "Сы́не Бо́жий,",
      "поми́луй мя́,",
      "гре́шнаго.",
    ],
  },
];

export default prays;
